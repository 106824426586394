import React, { useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'
import Button from '@base/parts/button'

import UIbars from '@elements/ui-bars'
import Title from '@elements/heading'

const DLCCosmeticCollection = (props) => {
  const ref = useRef(null)

  if (!props.data) {
    return <></>
  }

  const cosmeticCollectionData = props?.data?.data.cosmeticCollectionContent
  const button = cosmeticCollectionData?.button
  const dlcID = props.data.data.slug

  return (
    <section className="dlc-cosmetic-collection-wrapper">
      <div id={`${props.data?.data?.slug}-cosmetic-collection`} className="relative -top-56 lg:-top-72 pointer-events-none" />
      <div
        ref={ref}
        className="dlc-cosmetic-collection relative lg:container pb-44 lg:pb-32"
      >
        <div className="relative flex w-full">
          <div className="content pt-18 w-full lg:container">
            <div className="relative z-10 mx-auto flex h-full w-full flex-col lg:flex-row">
              <div className="cosmetic-collection-content relative z-10 mx-auto h-full pb-24 md:pb-0 lg:mt-20 w-full md:w-11/12 xxxl:w-3/4 ">
                <div className="cosmetic-collection-content-background" />
                <div className="collection-presentation flex flex-col md:flex-row mb-8 md:mb-0">
                  <div className="w-full md:w-1/2 content flex flex-col pt-16 lg:py-32 px-16 md:px-0">
                    <Image
                      media={props.data?.logo}
                      className="dlc-content-logo h-auto"
                    />
                    <Title
                      title={cosmeticCollectionData?.title}
                      titleTag="h3"
                      className={`title`}
                    />
                    <Markdown
                      className="dlc-content-shortDescription"
                      children={cosmeticCollectionData?.subtitle}
                    />
                    <div className="description mb-8 lg:mb-12">
                      {cosmeticCollectionData?.text.data.text}
                    </div>
                    {button && (
                      <div className="hidden md:block w-full px-6 lg:px-0">
                        <Button
                          link={button?.link}
                          trigger={button?.trigger}
                          style={button?.style}
                          size="lg"
                          globalData={props.globalData}
                          pageContext={props.pageContext}
                          eventCategory={button?.eventCategory}
                          eventAction={button?.eventAction}
                          eventLabel={button?.eventLabel}
                          eventElement={button?.eventElement}
                          eventType={
                            button?.eventCategory === 'buy' ? 'internalLink' : 'form'
                          }
                          eventLocation="dlc"
                        >
                          <span>{button?.label}</span>
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 relative -mt-36 md:mt-0">
                    <Image
                      media={cosmeticCollectionData?.backgroundImage}
                      className="dlc-cosmetic-collection-image h-[42rem] md:h-full right-0 w-full md:w-[130%]"
                    />
                  </div>
                </div>
                {cosmeticCollectionData?.collectionImage && (
                  <div className={`gatsby-hidden-mobile -ml-[5.5rem] mt-20 pr-16 pb-16`}>
                    <Image
                      media={cosmeticCollectionData.collectionImage}
                      className={`w-full h-auto`}
                    />
                  </div>
                )}
                {cosmeticCollectionData?.collectionImageMobile && (
                  <div className={`gatsby-hidden-desktop w-full shrink-0 pb-20 px-8 md:pl-0 md:mt-12`}>
                    <Image
                      media={cosmeticCollectionData.collectionImageMobile}
                      className={`w-full h-auto`}
                    />
                  </div>
                )}
                {button && (
                  <div className="w-full flex md:hidden justify-center">
                    <Button
                      link={button?.link}
                      trigger={button?.trigger}
                      style={button?.style}
                      size="lg"
                      globalData={props.globalData}
                      pageContext={props.pageContext}
                      eventCategory={button?.eventCategory}
                      eventAction={button?.eventAction}
                      eventLabel={button?.eventLabel}
                      eventElement={button?.eventElement}
                      eventType={
                        button?.eventCategory === 'buy' ? 'internalLink' : 'form'
                      }
                      eventLocation="dlc"
                    >
                      <span>{button?.label}</span>
                    </Button>
                  </div>
                )}
                {dlcID === 'sector-2-shattered-peak' ? (
                  <StaticImage
                    src="../../../assets/img/MyM-ui-ruler-sector-2.png"
                    alt=""
                    className="w-40 lg:w-56 h-auto gatsby-absolute -translate-x-1/2 left-1/2 lg:translate-none lg:left-auto lg:right-20 -bottom-24"
                    loading="lazy"
                  />
                ) : (
                  <StaticImage
                    src="../../../assets/img/MyM-ui-ruler-sector-1.png"
                    alt=""
                    className="w-40 lg:w-56 h-auto gatsby-absolute -translate-x-1/2 left-1/2 lg:translate-none lg:left-auto lg:right-20 -bottom-24"
                    loading="lazy"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UIbars
        index={1}
        container={ref}
        display="left"
        theme={dlcID === 'sector-2-shattered-peak' ? 'shattered-peak' : (dlcID === 'sector-1-dreadshore' ? 'dreadshore' : '')}
      />
    </section>
  )
}
export default DLCCosmeticCollection
