import React, { useState, useRef, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import useWindowSize from '@base/utils/hooks/useWindowSize'
import Image from '@base/parts/image'

import UIbars from '@elements/ui-bars'
import Title from '@elements/heading'

const AdditionalContent = (props) => {
  const ref = useRef(null)
  const windowSize = useWindowSize()
  const [selectedImageUrl, setSelectedImageUrl] = useState()

  let isMobile = false
  if (windowSize.width <= 991) {
    isMobile = true
  }
  const additionalContentData = props?.data?.additionalContent
  const dlcID = props.data.slug

  useEffect(() => {
    if (additionalContentData) {
      const img = additionalContentData?.images[0]
      setSelectedImageUrl(img?.localFile.publicURL)
    }
  }, [])

  const changeBgImage = (imageUrl) => {
    setSelectedImageUrl(imageUrl)
  }

  if (!props.data) {
    return <></>
  }
  return (
    <section className="dlc-additional-content-wrapper">
      <div
        ref={ref}
        className="dlc-additional-content pb-44 lg:pb-64"
      >
        <div id={`${props.data.slug}-additionnal-content`} className="relative -top-48 lg:-top-48 pointer-events-none" />
        <div className="w-full flex relative">
          <div className="lg:container content w-full pt-12 lg:pt-72">
            <div className="mx-auto w-full lg:w-11/12 xxxl:w-3/4 flex flex-col lg:flex-row relative z-10 lg:mb-40 h-full px-6 lg:px-0">
              <div className="carousel-section h-full w-full lg:w-2/3 xl:w-1/2">
                <Image
                  media={additionalContentData?.logo}
                  className="dlc-content-logo h-auto"
                />
                <Title
                  title={additionalContentData?.title}
                  titleTag="h3"
                  className={`title`}
                />
                <div className="shortDescription">
                  <span className="relative z-10">
                    {additionalContentData?.shortDescription}
                  </span>
                  <div className="description-mask"></div>
                </div>
                <div className="description mb-12 lg:mb-6">
                  {additionalContentData?.description}
                </div>
                {isMobile && (
                  <>
                    <div className="carousel">
                      {additionalContentData?.images?.map((image, index) => (
                        <div
                          key={`image-container-${index}`}
                          onClick={() =>
                            changeBgImage(image.localFile.publicURL)
                          }
                          className={
                            image.localFile.publicURL === selectedImageUrl
                              ? 'bg-container-img selected'
                              : 'bg-container-img'
                          }
                        >
                          <Image media={image} className="h-full" />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div className="flex w-full justify-center lg:justify-start">
                  {additionalContentData?.images?.map((image, index) => (
                    <div
                      key={`image-thumb-${index}`}
                      onClick={() => changeBgImage(image.localFile.publicURL)}
                      className={
                        image.localFile.publicURL === selectedImageUrl
                          ? 'carousel-img selected'
                          : 'carousel-img'
                      }
                    >
                      <Image
                        media={image}
                        key={`image-${index}`}
                        className="gatsby-absolute left-0 top-0 h-full w-full"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-container">
            {additionalContentData?.images?.map((image, index) => (
              <div
                key={`image-container-${index}`}
                onClick={() => changeBgImage(image.localFile.publicURL)}
                className={
                  image.localFile.publicURL === selectedImageUrl
                    ? 'bg-container-img selected'
                    : 'bg-container-img'
                }
              >
                <Image media={image} className="h-full" />
              </div>
            ))}
          </div>
        </div>
        <div className="content w-full lg:container">
          {additionalContentData?.packElement?.length &&
            additionalContentData.packElement?.map((pack, index) => (
              <div
                className="pack-box mx-auto w-full lg:w-11/12 xxxl:w-3/4"
                key={`pack-${pack.title}-${index}`}
              >
                <div className="flex w-full flex-col">
                  <Image
                    media={additionalContentData?.logo}
                    className="logo h-auto w-56"
                  />
                  <div className="title">{pack.title}</div>
                  <div className="shortDescription">
                    <span className="relative z-10">
                      {pack.shortDescription}
                    </span>
                    <div className="description-mask"></div>
                  </div>
                  <div className="description">{pack.text?.data?.text}</div>
                </div>
                <div className="mt-12 flex w-full">
                  <Image media={isMobile ? pack?.thumbnail : pack?.image} />
                </div>
                <div className="pack-box-background" />
              </div>
            ))}
        </div>
        <div className="pack-box-background-sub gatsby-absolute bottom-0 right-0 h-80 w-full">
          {dlcID === 'sector-1-dreadshore' && (
            <StaticImage
              src="../../../assets/img/MYM-deco-pack-bg-2.jpg"
              alt=""
              className="h-full w-full"
              loading="lazy"
            />
          )}
        </div>
        {dlcID === 'sector-2-shattered-peak' ? (
          <StaticImage
            src="../../../assets/img/MyM-ui-ruler-sector-2.png"
            alt=""
            className="gatsby-absolute gatsby-hidden-desktop bottom-20 left-1/2 h-auto w-56 -translate-x-1/2"
            loading="lazy"
          />
        ) : (
          <StaticImage
            src="../../../assets/img/MyM-ui-ruler-sector-1.png"
            alt=""
            className="gatsby-absolute gatsby-hidden-desktop bottom-20 left-1/2 h-auto w-56 -translate-x-1/2"
            loading="lazy"
          />
        )}
      </div>
      <UIbars
        index={1}
        container={ref}
        theme={dlcID === 'sector-2-shattered-peak' ? 'shattered-peak' : (dlcID === 'sector-1-dreadshore' ? 'dreadshore' : '')}
      />
    </section>
  )
}

export default AdditionalContent
