import React from 'react'
import Image from '@base/parts/image'
import Button from '@base/parts/button'
import { motion } from 'framer-motion'
import MyMStaticKeyart from '@img/MyM-keyart-static.jpg'

const DLCPageHeader = (props) => {
  if (!props.data) {
    return <></>
  }

  const staticKeyart = {
    url: MyMStaticKeyart,
  }

  return (
    <section
      className={`dlc-header ${ props?.globalData?.isBannerEnabled ? 'mt-[4.5rem]' : 'mt-0' }`}
    >
      <div className="container relative z-10 h-full">
        <div className="mx-auto h-full w-full px-6 pb-12 pt-20 lg:w-11/12 lg:p-0 xxxl:w-3/4">
          <div className="dlc-header-content flex h-full w-full flex-col  lg:w-[60%]">
            <div className="dlc-header-logo-container flex w-full flex-col">
              {props.data?.sectorNumber && (
                <span className="mb-4 w-full text-center font-sans text-[1rem] font-normal uppercase tracking-[0.05rem] text-white md:text-[2.5rem] md:tracking-[0.2rem] lg:text-[1.55vw] lg:tracking-[0.4rem]">
                  Sector {props.data.sectorNumber}
                </span>
              )}
              {props.data?.logo && (
                <Image media={props.data?.logo} className="dlc-header-logo" />
              )}
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}
              className="dlc-buttons flex flex-col space-y-4 md:mt-12 md:space-x-8 lg:flex-row lg:flex-nowrap lg:space-y-0"
            >
              {props.data?.buttons?.map((button, i) => (
                <Button
                  id={button.buttonId}
                  link={button.link}
                  trigger={button.trigger}
                  style={button.style}
                  key={`dlcBtn-${i}-${button.id}`}
                  size="lg"
                  globalData={props.globalData}
                  pageContext={props.pageContext}
                  eventCategory={button.eventCategory}
                  eventAction={button.eventAction}
                  eventLabel={button.eventLabel}
                  eventElement={button.eventElement}
                  eventType={
                    button.eventCategory === 'buy' ? 'internalLink' : 'form'
                  }
                  eventLocation="dlc"
                  icon={button.icon ? button.icon : ''}
                  ratingTimeBuffer={1}
                  modalContent={
                    button.trigger === 'buyModal' && {
                      media: staticKeyart,
                      title: 'Wishlist now!',
                    }
                  }
                >
                  <span>{button.label}</span>
                </Button>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
      {props.data?.keyart && (
        <Image
          media={props.data?.keyart}
          className="dlc-header-background gatsby-absolute right-0 top-0 h-full w-full lg:w-[60%]"
        />
      )}
    </section>
  )
}

export default DLCPageHeader
