import React, { useRef, useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Thumbs, Controller, Parallax, Lazy, EffectFade, EffectCreative } from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-creative'

import useWindowSize from '@base/utils/hooks/useWindowSize'
import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'
import Button from '@base/parts/button'

import Title from '@elements/heading'

const ArsenalPack = (props) => {
  const section = useRef(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const [sliderView, setSliderView] = useState(null)
  const windowSize = useWindowSize()
  const data = props.data
  const stringsLabels = props.globalData.stringsLabels
  const dlcID = props.data.slug

  useEffect(() => {
    if (windowSize.width <= 991) {
      setSliderView('mobile')
    } else {
      setSliderView('desktop')
    }
  }, [])

  return (
    <section
      ref={section}
      className={`relative lg:container pb-24 pt-48 lg:pt-48 lg:pb-24 overflow-hidden`}
    >
      <div id={`${data.slug}-arsenal-pack`} className="relative -top-72 lg:-top-72 pointer-events-none" />
      <div className="mx-auto w-full lg:w-11/12 xxxl:w-3/4 flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 flex flex-col justify-between px-16 lg:px-0">
          <div className="flex flex-col">
            <Image
              media={data?.logo}
              className="dlc-content-logo h-auto"
            />
            <Title
              container={section}
              title={data?.title ? data.title : stringsLabels?.arsenalPack}
              titleTag='h3'
              className={`dlc-content-title`}
            />
            {data?.subTitle && (
              <Markdown
                className="dlc-content-shortDescription"
                children={data.subTitle}
              />
            )}
            {data?.arsenalPackElement && (
              <>
                <div className="flex flex-row mb-12 mt-6 lg:my-0">
                  <Swiper
                    className="dlc-carousel-thumbs lg:mt-12"
                    allowTouchMove={false}
                    slidesPerView="auto"
                    modules={[Thumbs]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                  >
                    {data?.arsenalPackElement?.map((thumb, i) => (
                      <SwiperSlide
                        key={`arsenal-slider-thumb-${i}`}
                        className="dlc-carousel-thumb-wrapper cursor-pointer"
                      >
                        <div className='dlc-carousel-thumb'>
                          <Image
                            media={thumb.thumbnail}
                            className="object-cover"
                          />
                          <div className='dlc-carousel-thumb-decorations'>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {sliderView === 'mobile' && (
                    <div className="w-full sm:w-1/2 relative gatsby-hidden-desktop">
                      <div className="dlc-carousel-preview-decoration" />
                      {data?.arsenalPackElement && (
                        <Swiper
                          slidesPerView="1"
                          modules={[Thumbs, Controller, Parallax, EffectFade, EffectCreative, Lazy]}
                          loop
                          effect={"creative"}
                          creativeEffect={{
                            prev: {
                              translate: [0, 0, 0],
                              opacity: 0
                            },
                            next: {
                              translate: ["5%", 0, 0],
                              opacity: 1
                            },
                          }}
                          speed={750}
                          thumbs={{ swiper: thumbsSwiper }}
                          allowTouchMove={false}
                          onSwiper={setSecondSwiper}
                          controller={{ control: firstSwiper }}
                          parallax={true}
                          preloadImages={false}
                          lazy={{ loadPrevNext: true }}
                          className="dlc-carousel-preview"
                        >
                          {data?.arsenalPackElement?.map((preview, i) => (
                            <SwiperSlide
                              key={`arsenal-slider-image-${i}`}
                              className={`dlc-carousel-element-${i}`}
                            >
                              <div className="dlc-carousel-element">
                                <Image
                                  media={preview.image}
                                  className=""
                                />
                              </div>
                              {preview?.shortDescription && (
                                <Markdown
                                  className="dlc-carousel-element-title"
                                  children={preview.shortDescription}
                                />
                              )}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      )}
                    </div>
                  )}
                </div>
                <Swiper
                  className="dlc-carousel-description text-white w-full"
                  slidesPerView="1"
                  modules={[Thumbs, Controller, EffectFade]}
                  loop
                  allowTouchMove={false}
                  onSwiper={setFirstSwiper}
                  controller={{ control: secondSwiper }}
                  effect="fade"
                >
                  {data?.arsenalPackElement?.map((description, i) => (
                    <SwiperSlide
                      key={`arsenal-slider-text-${i}`}
                      className=""
                    >
                      {description.title && (
                        <h4 className="dlc-carousel-title mb-6 lg:mb-8 p-0">
                          <Markdown
                            children={description.title}
                            className="max-w-[60%] block"
                          />
                          {dlcID === 'sector-2-shattered-peak' ? (
                            <StaticImage
                              src="../../../assets/img/MyM-ui-ruler-sector-2.png"
                              alt=""
                              className="w-40 lg:w-56 h-auto gatsby-absolute right-0 top-[3px] md:bottom-0 md:top-auto"
                              loading="lazy"
                            />
                          ) : (
                            <StaticImage
                              src="../../../assets/img/MyM-ui-ruler-sector-1.png"
                              alt=""
                              className="w-40 lg:w-56 h-auto gatsby-absolute right-0 top-[3px] md:bottom-0 md:top-auto"
                              loading="lazy"
                            />
                          )}
                        </h4>
                      )}
                      {description.text && (
                        <Markdown
                          className="dlc-carousel-text mb-12 lg:mb-12"
                          children={description.text?.data?.text}
                        />
                      )}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </>
            )}
          </div>
          <div className="w-full flex justify-center lg:justify-start">
            <Button
              link={data?.button?.link}
              trigger={data?.button?.trigger}
              style={data?.button?.style}
              size="lg"
              globalData={props.globalData}
              pageContext={props.pageContext}
              eventCategory={data?.button?.eventCategory}
              eventAction={data?.button?.eventAction}
              eventLabel={data?.button?.eventLabel}
              eventElement={data?.button?.eventElement}
              eventType={
                data?.button?.eventCategory === 'buy' ? 'internalLink' : 'form'
              }
              eventLocation="dlc"
            >
              <span>{data?.button?.label}</span>
            </Button>
          </div>
        </div>
        {sliderView === 'desktop' && (
          <div className="w-full lg:w-1/2 relative gatsby-hidden-mobile">
            <div className="dlc-carousel-preview-decoration" />
            {data?.arsenalPackElement && (
              <Swiper
                slidesPerView="1"
                modules={[Thumbs, Controller, Parallax, EffectFade, EffectCreative, Lazy]}
                loop
                effect={"creative"}
                creativeEffect={{
                  prev: {
                    translate: [0, 0, 0],
                    opacity: 0
                  },
                  next: {
                    translate: ["5%", 0, 0],
                    opacity: 1
                  },
                }}
                speed={750}
                thumbs={{ swiper: thumbsSwiper }}
                allowTouchMove={false}
                onSwiper={setSecondSwiper}
                controller={{ control: firstSwiper }}
                parallax={true}
                preloadImages={false}
                lazy={{ loadPrevNext: true }}
                className="dlc-carousel-preview"
              >
                {data?.arsenalPackElement?.map((preview, i) => (
                  <SwiperSlide
                    key={`arsenal-slider-image-${i}`}
                    className={`dlc-carousel-element-${i}`}
                  >
                    <div className="dlc-carousel-element">
                      <Image
                        media={preview.image}
                        className=""
                      />
                    </div>
                    {preview?.shortDescription && (
                      <Markdown
                        className="dlc-carousel-element-title"
                        children={preview.shortDescription}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default ArsenalPack