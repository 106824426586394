import React, { createContext, useState } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import ProductCard from './dlc-product-card'

export const ProductsContext = createContext()

const DLCProducts = (props) => {
  const [openProduct, setOpenProduct] = useState(null)
  const [refLoad, inViewLoad] = useInView({
    threshold: 0.2,
    triggerOnce: true
  })

  const pageContext = props.pageContext
  const globalData = props.globalData
  const dlcGameID = props.data.slug
  const products = globalData.gameData.gameLinks
  const promos = globalData.gameData.promos
  let productData = ''
  let dlcContent = ''

  return (
    <ProductsContext.Provider value={{
      currentProduct: [openProduct, setOpenProduct]
    }}>
      <div
        ref={refLoad}
        className="dlc-related-products bg-generic-green pt-48 pb-48 lg:pb-80 -mb-48 lg:mb-0"
      >
        <div className={`lg:container px-16 md:px-0`}>
          {inViewLoad && (
            <motion.div
              className="mx-auto w-full md:w-11/12 xxxl:w-3/4 flex flex-col md:flex-row flex-wrap justify-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 0.5,
              }}
            >
              {products?.map((product, i) => (
                product.dlc && product.dlc?.slug === dlcGameID ? (
                  (() => {
                    productData = {
                      ...product.dlc,
                      gameID: product.gameID,
                      links: product.links,
                      type: product.type,
                      subType: product.subType
                    }
                    if (product.subType === 'arsenal') {
                      dlcContent = product.dlc?.arsenalPackContent
                      productData = {
                        ...productData,
                        title: product?.arsenalPackTitle ? product.arsenalPackTitle : (product?.dlcTitle ? product.dlcTitle : product.dlc.title),
                        inclusion: dlcContent?.arsenalPackInclusions,
                        keyartLogo: dlcContent?.arsenalPackKeyartAlt ? dlcContent?.arsenalPackKeyartAlt : dlcContent?.arsenalPackKeyart,
                        keyart: dlcContent?.arsenalPackKeyart,
                        logo: dlcContent?.arsenalPackLogo,
                        trailer: dlcContent?.trailer,
                        previewImage: dlcContent?.arsenalImage,
                        previewImageMobile: dlcContent?.arsenalImageMobile,
                        eventTitle: `Arsenal ${product?.dlc?.sectorNumber ? `sector ${product?.dlc?.sectorNumber}` : product?.dlc?.slug}`,
                        eventAction: 'buy arsenal'
                      }
                    }
                    if (product.subType === 'cosmetic') {
                      dlcContent = product.dlc?.cosmeticCollectionContent
                      productData = {
                        ...productData,
                        title: product?.cosmeticCollectionTitle ? product.cosmeticCollectionTitle : (product?.dlcTitle ? product.dlcTitle : product.dlc.title),
                        inclusion: dlcContent?.cosmeticCollectionInclusion,
                        keyartLogo: dlcContent?.cosmeticCollectionKeyartAlt ? dlcContent?.cosmeticCollectionKeyartAlt : dlcContent?.cosmeticCollectionKeyart,
                        keyart: dlcContent?.cosmeticCollectionKeyart,
                        logo: dlcContent?.cosmeticCollectionLogo,
                        trailer: dlcContent?.trailer,
                        previewImage: dlcContent?.collectionImage,
                        previewImageMobile: dlcContent?.collectionImageMobile,
                        eventTitle: `Collection ${product?.dlc?.sectorNumber ? `sector ${product?.dlc?.sectorNumber}` : 'SN'}`,
                        eventAction: 'buy collection'
                      }
                    }
                    if (product.subType === 'bundle') {
                      dlcContent = product.dlc?.bundleContent
                      productData = {
                        ...productData,
                        title: product?.bundleTitle ? product.bundleTitle : (product?.dlcTitle ? product.dlcTitle : product.dlc.title),
                        inclusion: dlcContent?.bundleInclusion,
                        keyartLogo: dlcContent?.bundleKeyartAlt ? dlcContent?.bundleKeyartAlt : dlcContent?.bundleKeyart,
                        keyart: dlcContent?.bundleKeyart,
                        logo: dlcContent?.bundleLogo,
                        logoArsenal: product.dlc?.arsenalPackContent?.arsenalPackLogo,
                        logoCollection: product.dlc?.cosmeticCollectionContent?.cosmeticCollectionLogo,
                        trailer: dlcContent?.trailer,
                        previewArsenalImage: product.dlc?.arsenalPackContent?.arsenalImage,
                        previewArsenalImageMobile: product.dlc?.arsenalPackContent?.arsenalImageMobile,
                        previewCollectionImage: product.dlc?.cosmeticCollectionContent?.collectionImage,
                        previewCollectionImageMobile: product.dlc?.cosmeticCollectionContent?.collectionImageMobile,
                        eventTitle: `Bundle ${product?.dlc?.sectorNumber ? `sector ${product?.dlc?.sectorNumber}` : product?.dlc?.slug}`,
                        eventAction: 'buy bundle'
                      }
                    }
                    return (
                      <ProductCard
                        product={productData}
                        pageContext={pageContext}
                        globalData={globalData}
                        promo={promos}
                        key={i}
                      />
                    )
                  })()
                ) : (
                  product.gameID && product.gameID === 'mym-base-game' && (
                    (() => {
                      productData = {
                        ...product,
                        eventTitle: 'Meet your maker DL',
                        eventAction: 'buy game'
                      }
                      return (
                        <ProductCard
                          product={productData}
                          pageContext={pageContext}
                          globalData={globalData}
                          promo={promos}
                          key={i}
                        />
                      )
                    })()
                  )
                )
              ))}
            </motion.div>
          )}
        </div>
      </div>
    </ProductsContext.Provider>
  )
}

export default DLCProducts
