import React, { useContext } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SVG from 'react-inlinesvg'

import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'
import close from '@base/icons/close.svg'

import { ProductsContext } from './dlc-products'
import Stores from '@elements/store/store-buttons'
import info from '@icons/info.svg'
import Promo from '@elements/product-promo'

const ProductCard = ({ product, pageContext, globalData, eventElement, promo }) => {
  const { currentProduct } = useContext(ProductsContext)
  const [openProduct, setOpenProduct] = currentProduct
  let productID = product?.gameID

  const openStoreToggle = (e) => {
    if (openProduct === e.target.value) {
      setOpenProduct(null)
    } else {
      setOpenProduct(e.target.value)
    }
  }

  return (
    <div className={`dlc-product-card relative flex flex-col justify-between items-center ${openProduct === product.gameID ? 'dlc-product-card-open' : (openProduct !== null ? '' : '')}`}>
      <div className="w-full h-full relative flex flex-col">
        <div className={`product-card-keyart full relative z-1`}>
          {product.keyartLogo ? (
            <Image
              media={product.keyartLogo}
              className={`gatsby-absolute top-0 left-0 w-full h-full z-under`}
            />
          ) : (
            <Image
              media={product?.image}
              className={`gatsby-absolute top-0 left-0 w-full h-full z-under`}
            />
          )}
          {promo?.discounts?.map((promo) => (
            <Promo
              product={productID}
              promo={promo}
            />
          ))}
          <button
            value={product.gameID}
            onClick={openStoreToggle}
            className="w-full h-full absolute left-0 top-0 cursor-pointer z-1"
          />
          <div
            className={`product-card-buy text-left text-white overflow-hidden w-full bg-[#000] left-0 bottom-0 z-10 absolute px-2 lg:px-0 transition-all duration-500 ${openProduct === product.gameID ? 'max-h-screen h-full' : 'max-h-0 h-0'}`}
            tabIndex={0}
          >
            <button
              value={product.gameID}
              onClick={openStoreToggle}
              className={`button-close duration-2500 absolute z-10 flex h-8 w-9 items-center justify-center transition-all cursor-pointer`}
            >
              <SVG
                onError={error => console.log(error.message)}
                cacheRequests={true}
                src={close}
                className="absolute z-10 h-2.5 w-2.5 text-white"
              />
            </button>
            <AnimatePresence>
              {openProduct === product.gameID && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.5,
                    delay: 0.15
                  }}
                  className="h-full flex flex-col justify-start px-4 lg:px-8 pb-8 pt-20"
                  tabIndex={0}
                >
                  <Stores
                    pageContext={pageContext}
                    layout="cta"
                    product={product.gameID}
                    gameData={globalData.gameData}
                    strings={globalData.stringsAll}
                    className="flex flex-col"
                    eventElement={eventElement}
                    eventAction={product?.eventAction ? product?.eventAction : null}
                    eventTitle={product?.eventTitle ? product?.eventTitle : null}
                  />
                  {product.gameID === 'mym-base-game' && (
                    <Markdown
                      children={globalData.stringsLabels.promoDisclaimer}
                      className="mt-4 font-display text-xs tracking-wider uppercase text-white/50"
                    />
                  )}
                  {product.type === 'dlc' && (
                    <div className="flex items-center mt-8 font-display text-xs tracking-wider uppercase text-white/50">
                      <SVG
                        onError={error => console.log(error.message)}
                        cacheRequests={true}
                        src={info}
                        className="h-6 w-6 text-white/50 mr-2"
                      />
                      <Markdown
                        children={globalData.stringsLabels.dlcDisclaimer}
                      />
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div className={`product-card-content relative pt-12 flex flex-col justify- items-center h-full w-full`}>
        <button
          className="button button-secondary button-narrow relative z-10 inline-flex w-full items-center justify-center md:w-auto lg"
          value={product.gameID}
          onClick={openStoreToggle}
        >
          <span className="pointer-events-none">
            {globalData.stringsAll.cta.buyNow}
          </span>
        </button>
      </div>
    </div >
  )
}

export default ProductCard