/*
  DLC Page TEMPLATE
  A template to display DLC pages
*/

import React from 'react'
import { graphql } from 'gatsby'

import PageLayout from '@base/layout/page-layout'
import PostContextProvider from '@base/utils/contexts/posts-context'

import { getGeneralData } from '@api/general-data'
import DLCPageHeader from '../components/blocks/dlc/dlc-header'
import DLCPageNav from '../components/blocks/dlc/dlc-nav'
import AdditionalContent from '../components/blocks/dlc/additional-content'
import ArsenalPack from '../components/blocks/dlc/arsenal-pack'
import DLCCosmeticCollection from '../components/blocks/dlc/cosmetic-collection'
import DLCProducts from '../components/blocks/dlc/dlc-products'

const DynamicDlcPage = ({ data, pageContext }) => {
  const generalData = getGeneralData(pageContext, data)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const { title } = data.pageData
  const stringsSlugs = globalData.stringsSlugs
  const parentPages = [
    {
      slug: stringsSlugs.dlc,
      shortName: stringsSlugs.dlcTitle,
    },
  ]

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs: true,
        postTitle: title,
        postParent: parentPages,
        strings: {
          back: globalData.stringsActions.back,
          home: globalData.stringsSlugs.homeTitle,
        },
      }}
      className={`dlc-${data?.pageData?.slug}`}
    >
      <PostContextProvider>
        <DLCPageHeader
          data={data.pageData}
          globalData={globalData}
          pageContext={pageContext}
        />
        <DLCPageNav
          pageContext={pageContext}
          strings={globalData.stringsLabels}
          links={{
            freeUpdates: data.pageData?.additionalContent ? true : false,
            arsenalPack: data.pageData?.arsenalPackContent ? true : false,
            cosmeticCollection: data.pageData?.cosmeticCollectionContent
              ? true
              : false,
          }}
          buyBtn={data.pageData?.buttons?.[0]}
        />
        {data.pageData?.additionalContent && (
          <AdditionalContent data={data.pageData} />
        )}
        <div
          className={
            data.pageData?.arsenalPackContent ||
              data.pageData?.cosmeticCollectionContent
              ? 'dlc-content-wrapper -mb-20'
              : ''
          }
        >
          <div className="dlc-content">
            {data.pageData?.arsenalPackContent && (
              <ArsenalPack
                pageContext={pageContext}
                data={{
                  ...data.pageData.arsenalPackContent,
                  slug: data.pageData?.slug,
                  logo: data.pageData?.additionalContent?.logo ? data.pageData?.additionalContent?.logo : data.pageData?.logo,
                }}
                globalData={globalData}
              />
            )}
            {data.pageData?.cosmeticCollectionContent && (
              <DLCCosmeticCollection
                pageContext={pageContext}
                data={{
                  data: data.pageData,
                  logo: data.pageData?.additionalContent?.logo ? data.pageData?.additionalContent?.logo : data.pageData?.logo,
                  strings: {
                    buyNow: globalData?.stringsAll?.cta.buyNow,
                  },
                }}
              />
            )}
          </div>
        </div>
        <DLCProducts
          pageContext={pageContext}
          data={data.pageData}
          globalData={globalData}
        />
      </PostContextProvider>
    </PageLayout>
  )
}

export default DynamicDlcPage

export const query = graphql`
  query DynamicDlcQuery($id: String!) {
    pageData: strapiDlc(id: { eq: $id }) {
      ...Dlc
    }
  }
`

export { Head } from '@base/layout/head'
