import React from 'react'

import AnchorLink from '@base/parts/anchor-link'

const DLCPageNav = (props) => {
  if (!props.links) {
    return <></>
  }
  const pageID = props.pageContext.slug

  return (
    props.links?.freeUpdates && (
      <nav
        className={`dlc-nav-wrapper sticky z-10 mx-auto lg:max-w-[80%] xl:max-w-[65%] xxxl:max-w-[45%] ${
          props?.globalData?.isBannerEnabled
            ? 'top-[110px] lg:top-[148px]'
            : 'top-[45px] lg:top-[80px]'
        }`}
      >
        <div className="dlc-nav flex w-full flex-row items-center justify-between font-display text-[1rem] uppercase tracking-wide lg:tracking-wider text-white md:text-sm lg:pr-32 lg:text-base lg:font-bold">
          {props.links?.freeUpdates && (
            <div className="dlc-nav-free-wrapper w-auto lg:max-w-[30rem]">
              <AnchorLink
                to={`${pageID}-additionnal-content`}
                className="dlc-nav-free"
              >
                <span>
                  {props.strings?.freeUpdates
                    ? props.strings?.freeUpdates
                    : 'Free Updates'}
                </span>
              </AnchorLink>
            </div>
          )}
          {props.links?.arsenalPack && (
            <AnchorLink
              to={`${pageID}-arsenal-pack`}
              className="lg:max-w-[20rem]"
            >
              <span>
                {props.strings?.arsenalPack
                  ? props.strings?.arsenalPack
                  : 'Arsenal Pack'}
              </span>
            </AnchorLink>
          )}
          {props.links?.cosmeticCollection && (
            <AnchorLink
              to={`${pageID}-cosmetic-collection`}
              className="ml-0 md:ml-[inherit] lg:max-w-[20rem]"
            >
              <span>
                {props.strings?.cosmeticCollection
                  ? props.strings?.cosmeticCollection
                  : 'Cosmetic Collection'}
              </span>
            </AnchorLink>
          )}
          {props.buyBtn && (
            <div className="mr-4 flex w-auto justify-center lg:m-0">
              <a
                href={props.buyBtn.link}
                className="dlc-nav-button border-2 border-solid border-white px-2 pb-2 pt-1 transition-all duration-300 lg:px-6"
              >
                {props.buyBtn.label}
              </a>
            </div>
          )}
        </div>
      </nav>
    )
  )
}

export default DLCPageNav
